<script lang="ts" setup>
  import { Navigation } from 'swiper/modules'
  import type { CCardItemData } from '@/components/common/CCardItem/CCardItem.types'
  import type { ImageProviderSource, ImgLoading } from '@/types/media'

  const props = withDefaults(
    defineProps<{
      id: string
      alignment?: 'left' | 'centre' | 'right'
      backgroundColor?: string
      darkMode?: boolean
      items: CCardItemData[]
      loading: ImgLoading
      preload?: boolean
      provider?: ImageProviderSource
    }>(),
    {
      alignment: 'left',
      backgroundColor: 'inherit',
      darkMode: false,
      loading: 'lazy',
      preload: false,
      provider: 'contentful'
    }
  )

  const slideLength = computed<number>(() => props.items.length)
</script>

<template>
  <div class="c-card-carousel">
    <client-only>
      <div :class="['swiper-button-prev', `swiper-button-prev-${id}`, { 'swiper-button-hidden': !mdAndUp }]" />
      <div :class="['swiper-button-next', `swiper-button-next-${id}`, { 'swiper-button-hidden': !mdAndUp }]" />

      <swiper-container
        :breakpoints="{
          0: {
            slidesPerView: 1.6,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,
            spaceBetween: 16,
            navigation: {
              enabled: false
            }
          },
          576: {
            slidesPerView: 2.2,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
            spaceBetween: 16,
            navigation: {
              enabled: false
            }
          },
          768: {
            slidesPerView: 3.14,
            spaceBetween: 32,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
            navigation: {
              enabled: true
            }
          },
          992: {
            slidesPerView: slideLength > 3 ? 4.16 : 3.14,
            spaceBetween: 32,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
            navigation: {
              enabled: true
            }
          },
          1200: {
            slidesPerView: slideLength > 3 ? 4.14 : 3.1,
            spaceBetween: 32,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
            navigation: {
              enabled: true
            }
          },
          1400: {
            slidesPerView: slideLength > 3 ? 4.14 : 3.1,
            spaceBetween: 32,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
            navigation: {
              enabled: true
            }
          }
        }"
        :modules="[Navigation]"
        :navigation="{
          nextEl: `.swiper-button-next-${id}`,
          prevEl: `.swiper-button-prev-${id}`
        }"
      >
        <swiper-slide v-for="item in items" :key="item.id" class="card-carousel-slide">
          <CCardItem
            :alignment
            :aspect-ratio="{
              desktop: { denominator: 4, numerator: 5 },
              mobile: { denominator: 4, numerator: 5 }
            }"
            :dark-mode="darkMode"
            :data="item"
            :loading
            :preload
            :provider
            class="slide-card-item"
          />
        </swiper-slide>
      </swiper-container>
    </client-only>
  </div>
</template>

<style lang="scss" scoped>
  .c-card-carousel {
    position: relative;
    margin: 0 auto;
    background-color: v-bind(backgroundColor);

    .card-carousel-slide {
      display: flex;
      height: auto;

      .slide-card-item {
        width: 100%;
        height: 100%;

        padding-bottom: 1px;
      }
    }

    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    .swiper-button-prev {
      position: absolute;
      left: 0;
      width: fit-content;

      @include screenMdAndUp {
        top: 0;
        height: 100%;
        margin-top: 0;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 64px;
        height: 64px;
        background-image: url('../../../assets/icons/components/swiper-nav-arrow.svg?url_encode');
        background-size: cover;
      }

      &.swiper-button-disabled {
        display: none;
      }

      @include screenMdAndUp {
        background: linear-gradient(90deg, v-bind(backgroundColor) 0%, rgba(255, 255, 255, 0) 100%);
      }

      @include screenXlAndUp {
        width: 96px;
      }
    }

    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    .swiper-button-next {
      position: absolute;
      right: 0;
      width: fit-content;

      &::after {
        content: '';
        display: inline-block;
        width: 64px;
        height: 64px;
        background-image: url('../../../assets/icons/components/swiper-nav-arrow.svg?url_encode');
        background-size: cover;
        transform: rotate(180deg);
      }

      &.swiper-button-disabled {
        display: none;
      }

      @include screenMdAndUp {
        top: 0;
        height: 100%;
        margin-top: 0;
        background: linear-gradient(270deg, v-bind(backgroundColor) 33%, rgba(255, 255, 255, 0) 100%);
      }

      @include screenXlAndUp {
        width: 96px;
      }
    }
  }
</style>
