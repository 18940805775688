<script lang="ts" setup>
  import type * as CSS from 'csstype'
  import type InputText from 'primevue/inputtext'
  import { useDropdownItemHandlers, useInputHandlers, useObeeScript } from './CGroupBookingObee.composables'
  import type { GroupBookingRestaurantsResponse, RestaurantGroupOrderingItem } from '~/types/api/group-bookings'

  defineProps<{
    backgroundColor?: CSS.Property.BackgroundColor
  }>()

  const { data: restaurantsData, error } = await useFetch<GroupBookingRestaurantsResponse>(
    '/api/group-bookings/restaurants'
  )

  if (!restaurantsData.value || error.value) {
    throw createError({
      ...error.value,
      statusCode: 404,
      statusMessage: 'No restaurants with group bookings data. No restaurants will be displayed.'
    })
  }

  const filteredResults = computed<RestaurantGroupOrderingItem[]>(() => {
    if (!restaurantsData.value?.restaurants) {
      return []
    }

    return restaurantsData.value.restaurants
      .filter((restaurant) => {
        return restaurant.label.toLowerCase().includes(searchInputValue.value.toLowerCase())
      })
      .slice(0, 5)
  })

  const inputElement = ref<InstanceType<typeof InputText> | null>(null)

  /**
   * This is used to determine if the user has selected a restaurant without typing anything in the input field.
   * Useful for highlighting the text in the input field after a selection has been made.
   */
  const hasSelectedWithoutInputChange = ref(false)
  const isDropdownMenuOpen = ref(false)

  const searchInputValue = ref('')
  const selectedRestaurant = ref<RestaurantGroupOrderingItem | null>(null)

  const { initObee, destroyObee } = useObeeScript(selectedRestaurant)

  function dropdownItemClickHandler(restaurant: RestaurantGroupOrderingItem) {
    searchInputValue.value = restaurant.label
    isDropdownMenuOpen.value = false

    destroyObee()

    selectedRestaurant.value = restaurant

    initObee()

    hasSelectedWithoutInputChange.value = true
  }

  function inputFocusHandler(event: FocusEvent) {
    if (!hasSelectedWithoutInputChange.value) {
      return
    }

    // Highlight the text in the input field to allow the user to easily type over it
    const input = event.target as HTMLInputElement
    input.select()
  }

  function inputInputHandler(_event: Event) {
    isDropdownMenuOpen.value = searchInputValue.value.length !== 0

    if (hasSelectedWithoutInputChange.value) {
      hasSelectedWithoutInputChange.value = false
    }
  }

  const {
    resultItemRefs,
    dropdownItemKeydownShiftTabHandler,
    dropdownItemKeydownTabHandler,
    dropdownItemKeydownUpArrowHandler,
    dropdownItemKeydownDownArrowHandler,
    dropdownItemKeydownEscapeHandler
  } = useDropdownItemHandlers({
    filteredResults,
    inputElement,
    isDropdownMenuOpen
  })

  const { inputKeydownDownHandler, inputKeydownEscHandler } = useInputHandlers({
    resultItemRefs,
    isDropdownMenuOpen
  })
</script>

<template>
  <div class="c-group-booking-obee">
    <div class="dropdown">
      <FloatLabel variant="in">
        <IconField>
          <InputText
            ref="inputElement"
            v-model="searchInputValue"
            :maxlength="256"
            fluid
            @focus="inputFocusHandler"
            @input="inputInputHandler"
            @keydown.esc="inputKeydownEscHandler"
            @keydown.down.prevent="inputKeydownDownHandler"
          />

          <InputIcon
            :pt="{
              root: {
                class: 'custom-input-icon'
              }
            }"
          >
            <icon-ionic-chevron-down
              :aria-expanded="isDropdownMenuOpen"
              class="chevron"
              color="var(--color-base-black)"
              height="16"
              width="16"
            />
          </InputIcon>
        </IconField>

        <label for="inputElement">Enter Your Suburb</label>
      </FloatLabel>

      <div class="dropdown-menu-wrapper">
        <ul v-show="isDropdownMenuOpen" class="dropdown-menu" role="menu">
          <li
            v-for="(result, index) in filteredResults"
            :key="result.id"
            :ref="(el) => (resultItemRefs[index] = el as Element)"
            class="dropdown-menu-item"
            role="menuitem"
            tabindex="0"
            @click="dropdownItemClickHandler(result)"
            @keydown.enter="dropdownItemClickHandler(result)"
            @keydown.esc="dropdownItemKeydownEscapeHandler"
            @keydown.tab.exact="dropdownItemKeydownTabHandler($event, index)"
            @keydown.shift.tab.exact="dropdownItemKeydownShiftTabHandler($event, index)"
            @keydown.up.prevent="dropdownItemKeydownUpArrowHandler(index)"
            @keydown.down.prevent="dropdownItemKeydownDownArrowHandler(index)"
          >
            <span>{{ result.label }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div :key="selectedRestaurant?.id" class="obee-widget-wrapper">
      <client-only>
        <div
          :data-obee-url="selectedRestaurant?.slug"
          class="obee-widget"
          data-obee-add-height="50"
          data-obee-min-height="450"
          data-obee-scrolltop="true"
          data-obee-widget-type="booking"
        />
      </client-only>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .c-group-booking-obee {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    height: fit-content;

    background-color: v-bind(backgroundColor);
  }

  .dropdown {
    position: relative;
    width: min(100%, 652px);

    .dropdown-menu-wrapper {
      position: absolute;
      top: calc(100% + 8px);
      width: inherit;

      .dropdown-menu {
        box-shadow: var(--box-shadow-md);
        padding: 0;

        .dropdown-menu-item {
          display: flex;
          flex-direction: row;
          gap: 8px;
          padding: 16px;
          background-color: var(--color-base-white);
          cursor: pointer;

          &:hover,
          &:focus,
          &:active {
            background-color: var(--color-base-red);
            color: var(--color-base-white);
          }
        }
      }
    }

    // eslint-disable vue-scoped-css/no-unused-selector
    .custom-input-icon {
      --p-icon-size: 1rem;
    }

    .chevron {
      width: 1rem;
      height: 1rem;
      margin-left: 4px;
      cursor: pointer;

      -moz-transition: transform 250ms;
      -webkit-transition: transform 250ms;
      transition: transform 250ms;
    }

    .chevron[aria-expanded='true'] {
      transform: rotate(180deg);
    }

    .chevron[aria-expanded='false'] {
      transform: rotate(0deg);
    }
  }

  .obee-widget-wrapper {
    width: min(100%, 652px);
    min-height: 80px;
  }
</style>
