<script lang="ts" setup>
  // Validation occurs:
  // - Validate on Change/Blur initially (when the user leaves the control), let’s call this lazy mode.
  // - If the field is invalid, switch the validation to validate on input (when the user types), let’s call this aggressive mode.
  // - If the field is valid, go back to “lazy” mode, otherwise, be “aggressive”.
  // See https://vee-validate.logaretm.com/v4/guide/composition-api/custom-inputs#handling-events

  import type InputText from 'primevue/inputtext'
  import { camelCaseToKebabCase } from '~/utils/string'

  // FIXME: Field validates when the user clicks down on the icon

  const props = withDefaults(
    defineProps<{
      disabled?: boolean
      iconPosition?: 'start' | 'end'
      label: string
      name: string
      maxlength?: number
      validateInitialValue?: boolean
    }>(),
    {
      disabled: false,
      iconPosition: 'end',
      maxlength: 254
    }
  )

  const emits = defineEmits<{
    (event: 'blur', payload: FocusEvent): void
    (event: 'change', payload: Event): void
    (event: 'input', payload: Event): void
  }>()

  const inputElement = ref<InstanceType<typeof InputText> | null>(null)
  defineExpose({ inputElement })

  const selectorName = computed(() => camelCaseToKebabCase(props.name))

  const { value, errorMessage, handleBlur, handleChange, validate, meta } = useField<string | null | undefined>(
    () => props.name,
    undefined,
    {
      validateOnValueUpdate: false
    }
  )

  onMounted(() => {
    // If there is an initial value on mount, and the field is not validated, validate it.
    if (props.validateInitialValue && !(value.value === undefined || value.value === null) && !meta.validated) {
      validate()
    }
  })

  function onBlur(event: FocusEvent) {
    emits('blur', event)
    handleBlur(event, true)
  }

  function onInput(event: Event) {
    emits('input', event)
    handleChange(event, !!errorMessage.value)
  }
</script>

<template>
  <div class="c-input-text-field">
    <FloatLabel variant="in">
      <IconField>
        <InputIcon
          v-if="iconPosition === 'start'"
          :pt="{
            root: {
              class: 'custom-input-icon'
            }
          }"
        >
          <slot name="icon" />
        </InputIcon>

        <InputText
          :id="selectorName"
          ref="inputElement"
          v-model="value"
          :disabled
          :invalid="!!errorMessage"
          :maxlength
          fluid
          @blur="onBlur"
          @input="onInput"
        />

        <InputIcon
          v-if="iconPosition === 'end'"
          :pt="{
            root: {
              class: 'custom-input-icon'
            }
          }"
        >
          <slot name="icon" />
        </InputIcon>
      </IconField>

      <label :class="{ 'invalid': !!errorMessage }" :for="selectorName">{{ label }}</label>
    </FloatLabel>
    <small v-if="errorMessage" :id="`${selectorName}-error`" class="input-error-message">
      {{ errorMessage }}
    </small>
  </div>
</template>

<style lang="scss" scoped>
  // eslint-disable vue-scoped-css/no-unused-selector
  .custom-input-icon {
    --p-icon-size: 1.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .input-error-message {
    color: var(--color-alert-danger);
  }
</style>
