<script lang="ts" setup>
  withDefaults(
    defineProps<{
      variant?: 'success' | 'error'
    }>(),
    {
      variant: 'success'
    }
  )

  const slots = useSlots()
  const hasIconSlot = !!slots.icon
</script>

<template>
  <div
    :class="[
      'c-alert',
      'body',
      {
        'alert--success': variant === 'success',
        'alert--error': variant === 'error'
      }
    ]"
  >
    <div v-if="hasIconSlot" class="alert-icon">
      <slot name="icon" />
    </div>

    <span>
      <slot name="default" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
  .c-alert {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    padding: 12px 16px;

    .alert-icon {
      display: block;
      flex: 0 0 auto;
      width: 1rem;
      height: 1rem;
    }
  }

  .alert--success {
    background-color: var(--color-secondary-green-lightest);
    color: var(--color-base-green);

    .alert-icon {
      color: var(--color-base-green);
    }
  }

  .alert--error {
    background-color: var(--color-secondary-red-lightest);
    color: var(--color-text-body);

    .alert-icon {
      color: var(--color-base-red);
    }
  }
</style>
